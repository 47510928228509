<template>
  <span class="cs-menu-overlay"> <!-- id="cs-header-menu" class="cs-header-menu-block cs-block cs-no-padding" class="cs-base-block" -->
    <div >
        <div  :class="rootClasses">
            <div class="cs-nav-primary-container navbar-expand-xl">
                <div class="container">
                    <div class="cs-nav-primary">
                        <a v-if="data && data.logo" href="/"><img class="cs-logo" :src="data.logo" /></a>
                        <div class="toggler-wrapper">
                          <NavbarToggler @click.native="toggleOverlay" :class="overlayActiveClasses" />
                        </div>
                    </div>
                </div>
                <div class="cs-nav-overlay" v-if="showMenu" :class="overlayActiveClasses">
                    <div class="container">
                        <div class="cs-nav-primary-links">
                            <div>
                                <NestedLink v-for="(lvl1menuItem, index) in lvl1Menu" :index="index" :link="lvl1menuItem" :key="`first-level-link-${index}`" />
                            </div>
                        </div>
                        <div v-if="showInteractions" class="cs-interactions">
                            <span v-for="(button, index) in data.links" :key="`side-link-${index}`" v-show="button.login == isLoggedIn">
                                <CSButton :data="button.url" :title="button.linkText"></CSButton>
                            </span>
                            <!-- <CSButton  v-for="(button, index) in data.links" :key="`side-link-${index}`" v-if="button.url && button.login == isLoggedIn" :data="button.url" :title="button.linkText"/> -->
                            <!-- <a :href="button.url" v-for="(button, index) in data.links" :key="`side-link-${index}`">
                            {{ button.linkText }}
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </span>
</template>

<script>
  export default {
    name: "CSMenuOverlay",
    components: {
        NavbarToggler: () => import('./Utils/NavbarToggler.vue'),
        NestedLink: () => import('./Utils/NestedLink.vue'),
        CSButton: () => import('../CSButton.vue')
    },
    data() {
      return {
        uniqueId: "id" + Date.now(),
        build: {},
        active1LvlMenuItem: {
          selected: '',
          menu: {}
        },
        active2LvlMenuItem: {
          selected: '',
          menu: {}
        },
        overlayActive: false,
        showMenu:false
      };
    },
    methods: {
        toggleOverlay() {
            this.overlayActive = !this.overlayActive;
        }
    },
    computed: {
      showInteractions() {
        if (this.data?.links) {
          if (Array.isArray(this.data.links) && this.data.links.length) return true;

          if (typeof this.data.links == 'object' && Object.keys(this.data.links).length) return true;
        }

        return false;
      },
      rootClasses() {
        let result = {};

        if (this.overlayActive) result['overlay-active'] = true;

        return result;
      },
      overlayActiveClasses() {
        if (this.overlayActive) return 'active';

        return '';
      },
      isLoggedIn(){
        return this.data.isLoggedIn
      },
      lvl1Menu(){
        if(this.data){
          if(this.data.menu) return Object.values(this.data.menu);
          else return []
        }else return []
      }
    },
    mounted(){
        if(typeof window != 'undefined'){
            this.isLoggedIn = !this.cookies().loggedIn();
        }
        let self = this
        setTimeout(function(){
            self.showMenu = true
        },1000)
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    }
  }
</script>

<style lang="scss" scoped>
    @import "../../libs/common-styles.scss";
    @import "../../styles/main.scss";

    .cs-menu-overlay {
        
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        margin: auto;
        font-family: $font_family;
        background-color: #00274f;
        width: 100%;

        .toggler-wrapper {
          margin-left: auto;
          display: flex;
          align-items: center;
        }

        .cs-nav-primary-container.navbar-expand-xl {
            @media (max-width: 576px) {
                padding-left: 10px;
                padding-right: 10px;
            }
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
            padding-top: $default_margin / 2;
            padding-bottom: $default_margin / 2;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 8px;
                box-shadow: 0px 4px 4px rgba(29,140,242,0.08);
                z-index: 2;
            }
            .cs-nav-primary{
                position: relative;
                a{
                    display:inline-block;
                }

            }

            .cs-logo {
                z-index: 3;
                width: auto;
                height: 72px;
                object-fit: contain;
                object-position: center;
                max-width: 150px;
                margin-right: $default_margin;
            }

            .cs-nav-primary-links {
                display: flex;
                align-items: center;
                flex-direction: column;
                
                div {
                    width: 350px;

                    @media (max-width: 1199.98px) {
                        width: 100%;
                    }
                }
            }

            .cs-interactions {
                display: flex;
                flex: 1 0 auto;
                justify-content: center;
                margin-top: $default_margin;

                button,
                ::v-deep .cs-button {
                    color: #fff;
                    background: #00a983;
                    background: linear-gradient(70deg, #00a983 0%, #2bd784 100%);
                    padding: 10px 25px;
                    text-align: center;
                    outline: none;
                    transition-property: color, background-color;
                    transition-duration: 300ms;
                    transition-timing-function: ease-out;
                    border-radius: 40px;
                    min-width: 120px;
                    text-decoration: none;
                    margin-right: $default_margin;

                    &:last-child {
                        margin-right: 0px;
                    }
                    
                    &:hover,
                    &:focus,
                    &:focus-visible,
                    &:focus-within {
                        outline: none;
                        background-color: $bg_light;
                        color: $dark_blue;
                    }
                }
            }

            ::v-deep {
                .navbar-toggler {
                    display: block !important;
                    position: absolute;
                    z-index: 3;
                    right: 0;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                }
            }

            .cs-nav-overlay {
                opacity: 0;
                padding: 50px 0;
                transition: opacity 300ms ease-in;
                pointer-events: none;
                user-select: none;

                &.active {
                    opacity: 1;
                    pointer-events: unset;
                }

                top: 87px;
                width: 100%;
                height: calc( 100vh - 87px );
                position: absolute;
                background-color: #00274f;
                overflow: auto;
            }
        }
        .overlay-active {
            .cs-nav-primary::after {
                display: none;
            }
        }
    }
</style>